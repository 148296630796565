import { combineReducers } from "redux";

const bookingID = (state = '', action) => {
    switch (action.type) {
        case "SET_BOOKING_ID":
            return action.payload;
        default:
            return state;
    }
}

const BookingID = combineReducers({
    bookingID,
});

export default BookingID;