import React, { useState } from "react";
import { AppLayout } from "../../../layouts/AppLayout";
import "./numberGameBetslip.css";
import "./numberGame.css";

export const NumerGames = () => {
  const [slip, setSlip] = useState([]);

  const data = [
    [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
    [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
    [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
  ];

  const _getClass = (num) => {
    if (num <= 10 || (num >= 19 && num <= 28)) {
      return num % 2 == 0 ? "num black" : "num red";
    } else {
      return num % 2 == 0 ? "num red" : "num black";
    }
  };

  const addNumberInSlip = (singleCell) => {
    setSlip((state) => {
      return [...state, singleCell];
    });
  };

  const showSlip = () => {
    return slip.map((item) => {
      return (
        <>
          <div className="BLM-betSlipBox" id="highlightCompletedMatchBet_0">
            <div className="BLM-betSlipBox-content w-100 p-2">
              <div className="BLM-bet-details">
                <div className="BLM-betSlip-outcomeOdds">
                  <div className="BLM-betSlip-outcome truncate">
                    Royal Spin
                    {/* {betslip.teamOne} */}
                  </div>
                  <div className="BLM-betSlip-outcome truncate">
                    {/* {betslip.placedBet.odd} */}
                  </div>
                </div>
                <div className="BLM-betSlip-market">Your Pick: {item}</div>
                <div className="BLM-betSlip-fixture">
                  {/* {betslip.teamOne} vs {betslip.teamTwo} @{" "} */}
                  <span
                    className="BLM-betSlip-fixture"
                    data-dateandtime="12/06/2021 20:00:00"
                  >
                    {/* {betslip.date} */}
                  </span>
                </div>
              </div>
            </div>
            <div className="BLM-betSlipBox-close" data-selectionid="316625476">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
        </>
      );
    });
  };

  const firstRow = () => {
    return data.map((row, rowIndex) => {
      return (
        <tr className="nums">
          {rowIndex == 0 ? (
            <td className="num green zero" rowSpan={3}>
              <span>0</span>
            </td>
          ) : (
            <td className="hidden" />
          )}
          {row.map((singleCell) => {
            return (
              <td
                className={_getClass(singleCell)}
                onClick={() => {
                  addNumberInSlip(singleCell);
                }}
              >
                <span>{singleCell}</span>
              </td>
            );
          })}
          <td className="sector" data-sector={1}>
            <span className="vt">2 to 1</span>
          </td>
        </tr>
      );
    });
    // return  data[0].map((rowNum)=>{
    //     return (
    //       <td className={rowNum % 2 == 0?"num black":"num red"}><span>{rowNum}</span></td>
    //     )
    //   })
  };

  return (
    <>
      <AppLayout>
        <div className="row">
          <div className="col-lg-9 p-0  main-row">
            <div className="roulette m-0 ">
              <table className="w-100 mb-5">
                <tbody>
                  {firstRow()}

                  <tr>
                    <td className="empty" />
                    <td colSpan={4} className="sector" data-sector={4}>
                      1st 12
                    </td>
                    <td colSpan={4} className="sector" data-sector={5}>
                      2nd 12
                    </td>
                    <td colSpan={4} className="sector" data-sector={6}>
                      3rd 12
                    </td>
                    <td className="empty" />
                  </tr>
                  <tr>
                    <td className="empty" />
                    <td colSpan={2} className="sector" data-sector={7}>
                      1 to 18
                    </td>
                    <td colSpan={2} className="sector" data-sector={8}>
                      EVEN
                    </td>
                    <td colSpan={2} className="sector red" data-sector={9}>
                      RED
                    </td>
                    <td colSpan={2} className="sector black" data-sector={10}>
                      BLACK
                    </td>
                    <td colSpan={2} className="sector" data-sector={11}>
                      ODD
                    </td>
                    <td colSpan={2} className="sector" data-sector={12}>
                      19 to 36
                    </td>
                    <td className="empty" />
                  </tr>
                  <tr className="nums">
                    <td className="empty" />
                    <td colSpan={2} className="sector" data-sector={13}>
                      A
                    </td>
                    <td colSpan={2} className="sector" data-sector={14}>
                      B
                    </td>
                    <td colSpan={2} className="sector" data-sector={15}>
                      C
                    </td>
                    <td colSpan={2} className="sector" data-sector={16}>
                      D
                    </td>
                    <td colSpan={2} className="sector" data-sector={17}>
                      E
                    </td>
                    <td colSpan={2} className="sector" data-sector={18}>
                      F
                    </td>
                    <td className="empty" />
                  </tr>
                </tbody>
              </table>
              <div className="align-tracker">
                <div className="drawIDDiv">
                  <h4 className="DrawIDTitle">Draw ID</h4>
                  <span id="lblDrawID" className="drawIDNumber">
                    # 167439
                  </span>
                  <label id="lblTimer" />
                </div>
                <div className="drawTimer ">
                  <div className="drawTimerDigit ">
                    <label
                      id="lblMinDigit1"
                      clientidmode="Static"
                      className="  blink"
                    >
                      0
                    </label>
                  </div>
                  <div className="drawTimerDigit ">
                    <label
                      id="lblMinDigit2"
                      clientidmode="Static"
                      className="  blink"
                    >
                      0
                    </label>
                  </div>
                  <span className="clockDivider minutes">
                    <span className="dot top"></span>
                    <span className="dot bottom"></span>
                  </span>
                  <div className="drawTimerDigit">
                    <label
                      id="lblSecDigit1"
                      clientidmode="Static"
                      className="  blink"
                    >
                      0
                    </label>
                  </div>
                  <div className="drawTimerDigit">
                    <label
                      id="lblSecDigit2"
                      clientidmode="Static"
                      className="  blink"
                    >
                      9
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ">
            <div className="w-100 bg-light h-100">
              <div className="p-2" style={{ backgroundColor: "#2c2e83" }}>
                <b className="text-light">Betslip</b>
              </div>
              <div className="pl-2 main-slip-st">{showSlip()}</div>
              <div className="BLM-betSlip-acceptOddsChange-container p-2">
                <div className="BLM-betSlip-acceptOddsChange w-75">
                  <div className="BLM-checkbox mb-2">
                    <input type="checkbox" id="acceptOddschange" />
                    <label htmlFor="acceptOddschange" />
                    <span className="BLM-checkboxLabel">
                      Accept Odds Change
                    </span>
                  </div>
                  <div className="BLM-checkbox mb-2">
                    <input type="checkbox" id="keepPlacedBets" />
                    <label htmlFor="keepPlacedBets" />
                    <span className="BLM-checkboxLabel">
                      Keep bets in betslip
                    </span>
                  </div>
                </div>
                <div className="BLM-betSlip-clearAll">Clear All</div>
              </div>
              {/* --------------------------- footer --------------------------- */}
              <div className="BLM-betSlip-footer">
                <div className="BLM-addSelections-infoMsg" id="betInfoMsg">
                  {" "}
                  <a href="/" className="text-danger">
                    Add 2 more selections to receive a bonus of 10%
                  </a>
                </div>
                <div className="BLM-stakeBox-container">
                  <div className="BLM-freeBet" />
                  <div id="displayStakeBox">
                    <div className="BLM-stakeInputBox BLM-form-control">
                      {" "}
                      <span className="font-cls p-2">KSH</span>
                      <input
                        type="number"
                        className="w-50"
                        placeholder="Stake"
                        id="stakeValue"
                      />
                      <i className="sb-icon_close BLM-closeIcon p-2" />
                    </div>
                  </div>
                  <div id="displayFreeBetDropDown" />
                </div>
                <div className="BLM-totalStakeOddsWins">
                  <div className="BLM-accordion" id="getBetslipAccordianId">
                    <div className="BLM-accordion-header BLM-arrowAfter">
                      <div className="BLM-totalStakeOddsWins-content">
                        <div className="BLM-contentBlock font-cls">
                          <div className="BLM-leftContent">Total Odds:</div>
                          <div className="BLM-rightContent">24.75</div>
                        </div>
                        <div className="BLM-contentBlock BLM-wht font-cls">
                          <div className="BLM-leftContent">
                            Stake after tax:
                          </div>
                          <div
                            className="BLM-rightContent"
                            id="bindDeductedStakeValue"
                          >
                            KSH 0.00
                          </div>
                        </div>
                        <div className="BLM-contentBlock BLM-wht font-cls">
                          <div className="BLM-leftContent">Excise Tax:</div>
                          <div
                            className="BLM-rightContent"
                            id="bindExciseValue"
                          >
                            KSH 0.00
                          </div>
                        </div>
                        <div className="BLM-contentBlock BLM-wht font-cls">
                          <div className="BLM-leftContent">WHT:</div>
                          <div className="BLM-rightContent" id="bindTaxValue">
                            KSH 0.00
                          </div>
                        </div>
                        <div className="BLM-contentBlock">
                          <div className="BLM-leftContent BLM-txtHighlight font-cls">
                            To Win:
                          </div>
                          <div
                            className="BLM-rightContent BLM-txtHighlight font-cls"
                            id="bindWinningValue"
                          >
                            KSH 0.00
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="BLM-placeBet-container p-2">
                  <div id="displayPlaceBetButton">
                    <button className="btn BLM-btnPrimary active BLM-btnLarge BLM-btn BLM-btnPlaceBet w-100">
                      <span className="BLM-btnTxt text-light">Place Bet</span>
                    </button>
                  </div>{" "}
                  <div id="displayAcceptOddsButton" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};
