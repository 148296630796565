import React from "react";
import {withRouter} from "react-router-dom";

function MessageBox(props) {
    return (
        <>
            <div className="BLM-content" style={{display:'block'}}>
                <div className={`BLM-msgBox`} data-livenowleagueid={9999}>
                    <div className="BLM-msgBox-container">{props?.data}</div>
                </div>
            </div>
        </>
    );
}
export default withRouter(MessageBox);

