import React from "react";
import { Navbar } from "./partials/Navbar";
import { Sidebar } from "./partials/Sidebar";
import { Navbets } from "./partials/Navbets";
import styles from "../Components/Styles/Home.module.css";

export const AppLayout = (props) => {
  return (
    <div className="container-fluid" id="main-container">
      <div className="row">
        <div className="col-lg-2 side_nav">
          <Sidebar />
        </div>
        <div className="col-lg-10">
          <div className="row">
            <div className="col-lg-12">
              <Navbar />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
