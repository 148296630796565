import React, { useState, useEffect } from "react";
import {logDOM} from "@testing-library/react";
function Header(props) {
    const [active_tab, set_active_tab] = useState("Upcoming");
    useEffect(() => {
        if (props?.leagues?.length) {
            props?.setActiveLeagueId(props?.leagues[0]?.bb_id);
        }
    }, [props?.leagues]);
    return (
        <div className="BLM-layout-header">
            <div
                className="BLM-subHeader BLM-scrollableArea BLM-horizontal"
                id="subHeader"
            >
                <ul className="topHeaderAddActiveClass">
                    <li
                        className={`${active_tab == "Upcoming" ? "active" : ""}`}
                        data-id={props?.activeSportId}
                        onClick={() => {
                            set_active_tab("Upcoming");
                            // props?.getSportFixtures(props?.activeSportId);
                            props?.set_active_tab("Upcoming");
                            props?.headerClickChange("Upcoming");
                        }}
                    >
                        <button className="btn BLM-btnPrimary active BLM-btnLarge BLM-btn"><span
                            className="BLM-btnTxt text-light">All Leagues</span></button>
                    </li>
                    <li
                        className={active_tab == "TopLeagues" ? "active" : ""}
                        // data-id={}
                        onClick={() => {
                            // props?.getLeagueFixtures(league?.bb_id);
                            set_active_tab('TopLeagues');
                            props?.set_active_tab("TopLeagues");
                            props?.headerClickChange("TopLeagues");
                        }}
                    >
                        <button className="btn BLM-btnPrimary active BLM-btnLarge"><span
                            className="BLM-btnTxt text-light">Top Leagues</span></button>
                    </li>
                    <li
                        className={active_tab == "result" ? "active" : ""}
                        onClick={() => {
                            // props?.getLeagueFixtures(league?.bb_id);
                            set_active_tab('result');
                            props?.set_active_tab("result");
                            props?.headerClickChange("result");
                        }}
                    >
                        <button className="btn BLM-btnPrimary active BLM-btnLarge"><span
                            className="BLM-btnTxt text-light">Results</span></button>
                    </li>


                </ul>
            </div>
        </div>
    );
}

export default Header;
