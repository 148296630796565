import { Route, Redirect } from "react-router-dom";
import React from "react";

export default function AuthenticatedRoute({ component: Component, ...rest }) {
  const isLoggedIn = sessionStorage.getItem("token");

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component /> : <Redirect to="/login" />
      }
    ></Route>
  );
}
