import {combineReducers} from "redux";

const onlineCustomers = (state = [], action) => {
    switch (action.type) {
        case "SET_ONLINE_CUSTOMER":
            return action.payload;
        default:
            return state;
    }
};

const OnlineCustomer = combineReducers({
   onlineCustomers,
});

export default OnlineCustomer;