import { combineReducers } from "redux";

const balance = (state = 0, action) => {
    switch (action.type) {
        case "SET_BALANCE":
            return action.payload;
        default:
            return state;
    }
};

const Cashbalance = combineReducers({
    balance,
});

export default Cashbalance;