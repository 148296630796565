import { combineReducers } from "redux";
import get from "lodash/get";
import Betslip from "./Betslip/reducer";
import Cashbalance from "./Cashbalance/reducer";
import OnlineCustomer from "./OnlineCustomer/reducer";
import BookingID from "./BookingId/reducer";

const CommonState = (state = {}, action) => {
  const payload = get(action, "payload", null);
  switch (action.type) {
    default:
      return state;
  }
};
const rootReducer = combineReducers({
  CommonState,
  Betslip,
  Cashbalance,
  OnlineCustomer,
  BookingID,
});

export default rootReducer;
