import React from 'react'
import { AppLayout } from '../../../layouts/AppLayout'
import { Navbets } from '../../../layouts/partials/Navbets'

export const EnduserIndex = () => {
    return (
        <div>
            <AppLayout>
                <div className="col-lg-9">
                    <Navbets />
                </div>
                <br />
                <h1>Enduser Index</h1>
            </AppLayout>
        </div>
    )
}
