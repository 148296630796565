import React, { useState, useEffect } from "react";
import { getFixturesByLeagueIdCall } from "./repository";

function Filters(props) {
    return (
      <>
        <div className="col-lg-12">
          {props.active_tab == "result" ? (
            <div
              data-upcomingtimefilters="UpcomingMobileTimeFilterList"
              className="BLM-upcoming-filter-tabs"
              style={{ backgroundColor: "white" }}
            >
              <ul className="upcomingTimeList">
                <li
                  value="Today"
                  className={`${props.resultTab == "today" ? "active" : ""}`}
                  onClick={() => {
                    props.setResultTab("today");
                  }}
                >
                  Today
                </li>
                <li
                  value="nextday"
                  id="getnextdaydate"
                  className={`${
                    props.resultTab == "yesterday" ? "active" : ""
                  }`}
                  onClick={() => {
                    props.setResultTab("yesterday");
                  }}
                >
                  {moment().subtract(1, "days").format("D ddd")}
                </li>
                <li
                  value="nextafterday"
                  id="getnextafterdaydate"
                  className={`${
                    props.resultTab == "day_before_yesterday" ? "active" : ""
                  }`}
                  onClick={() => {
                    props.setResultTab("day_before_yesterday");
                  }}
                >
                  {moment().subtract(2, "days").format("D ddd")}
                </li>
                <li
                  value="nextafterday"
                  id="getnextafterdaydate"
                  className={`${props.resultTab == "all" ? "active" : ""}`}
                  onClick={() => {
                    props.setResultTab("all");
                  }}
                >
                  Week
                </li>
              </ul>
            </div>
          ) : (
            <div className="BLM-filterDropdowns">
              <div className="col-lg-12 d-flex align-items-center">
                <div className="BLM-select">
                  <select
                    id="ddn_upcomingdayfilters"
                    onChange={() => {
                      props?.setMatchTime(
                        document.getElementById("ddn_upcomingdayfilters").value
                      );
                      props?.filterClickChange();
                    }}
                    defaultValue={"all"}
                  >
                    <option value="Today">Today</option>
                    <option value="all">All</option>
                    <option value="Tomorrow">Tomorrow</option>
                    <option value="day_after">Day After</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
}

export default Filters;
