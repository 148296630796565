import React from "react";
import { GiSoccerBall, GiCartwheel } from "react-icons/gi";
import { CgGames } from "react-icons/cg";

export const Navbets = () => {
  return (
    <div className="row mb-2">
      <div className="col">
        <button type="button" className="btn btn-light btn-sm">
          <p className="text-start">SPORTS</p>
          <GiSoccerBall />
        </button>
      </div>
      <div className="col">
        <button type="button" className="btn btn-light btn-sm">
          <p className="text-start">Virtuals</p>
          <CgGames />
        </button>
      </div>
      <div className="col">
        <button type="button" className="btn btn-light btn-sm">
          <p className="text-start">Numbers</p>
          <GiCartwheel />
        </button>
      </div>

      <div className="col-9"></div>
    </div>
  );
};
