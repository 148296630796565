import React from "react";

function PageHeader(props) {
  const { fixture } = props;
  return (
    <>
      {Object.keys(fixture)?.length ? (
        <div className="BLM-pageHeader">
          <div
            className="BLM-pageBackButton"
            onClick={() => {
              props.history.goBack();
            }}
          >
            <div className="BLM-iconSvg">
              <svg
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                style={{ enableBackground: "new 0 0 24 24" }}
                xmlSpace="preserve"
              >
                <path d="M10.4,22.4l1.8-1.8l-7.3-7.3H24v-2.6H5l7.3-7.3l-1.8-1.8L0,12L10.4,22.4z" />
              </svg>
            </div>
          </div>
          <div className="BLM-pageHeader-title">
            {fixture?.competitors[0]?.name +
              " v " +
              fixture?.competitors[1]?.name}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PageHeader;
