import React, { useState } from "react";
import { GiSoccerBall, GiCartwheel } from "react-icons/gi";
import { CgGames } from "react-icons/cg";
import {getTicketBetData} from "../../Common/repository";

export const BetsNav = (props) => {
  const [sports, setSports] = useState([
    {
      name: "Soccer",
      icon_url: "/assets/media/soccer.svg",
    },
    {
      name: "Basketball",
      icon_url: "/assets/media/basketball.svg",
    },
    {
      name: "Baseball",
      icon_url: "/assets/media/baseball.svg",
    },
  ]);
  const callURL = () => {
    props.call()
  }

  //   const _renderSports = () => {
  //     return sports.map((sport) => {
  //       return (
  //         <button
  //           type="button"
  //           className="btn btn-light btn-sm mr-1 hov-cls active-cls"
  //           style={{ width: "100px" }}
  //         >
  //           <p className="text-center">{sport.name}</p>
  //           <GiSoccerBall style={{ height: "40px", width: "40px" }} />
  //           {/* <img src={`${sport.icon_url}`} alt="soccer" width={36} height={36} /> */}
  //         </button>
  //       );
  //     });
  //   };

  return (
    <div className="row mb-4 " style={{ height: "110px" }}>
      <div className="p-0 mr-2 d-flex " style={{}}>
        <button
          type="button"
          className={`btn btn-light btn-sm mr-1 hov-cls ${props.gameType=="sport" ? "active-cls" : ''}`}
          style={{ width: "100px" }}
          onClick={(e) => {
            props.setGameType("sport")
            callURL()
          }}
        >
          <p className="text-center">Sports</p>
          <GiSoccerBall style={{ height: "40px", width: "40px" }} />
          {/* <img src={`${sport.icon_url}`} alt="soccer" width={36} height={36} /> */}
        </button>
        {/* {_renderSports()} */}
        <button
          type="button"
          className={`btn btn-light btn-sm mr-1 hov-cls ${props.gameType=="virtual" ? "active-cls" : ''}`}
          style={{ width: "100px" }}
          onClick={(e) => {
            props.setGameType("virtual")
            callURL()
          }}
        >
          <p className="text-center">Virtuals</p>
          <CgGames style={{ height: "40px", width: "40px" }} />
          {/* <img src={`${sport.icon_url}`} alt="soccer" width={36} height={36} /> */}
        </button>
        <button
          type="button"
          className={`btn btn-light btn-sm mr-1 hov-cls ${props.gameType=="virtual" ? "active-cls" : ''}`}
          style={{ width: "100px" }}
          onClick={(e) => {
            props.setGameType("virtual")
            callURL()
          }}
        >
          <p className="text-center">Virtuals</p>
          <GiCartwheel style={{ height: "40px", width: "40px" }} />
          {/* <img src={`${sport.icon_url}`} alt="soccer" width={36} height={36} /> */}
        </button>
      </div>
    </div>
  );
};
