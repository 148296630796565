import React from "react";
import { useEffect, useState } from "react";
import { AppLayout } from "../../../layouts/AppLayout";
import "./detail.css";
import Header from "../../Common/Header";
import Filters from "../../Common/Filters";
import Fixtures from "../../Common/Fixtures";
import { Betslips } from "../../Common/Betslips";
import PageHeader from "../../Common/PageHeader";
import { withRouter } from "react-router-dom";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import FixtureDetails from "./FixtureDetails";
import axios from "axios";
import { API_URL } from "../../Common/constants";
import Loader from "../../Common/Loader";
import ScrollButton from "../../Common/ScrollButton";

const Detail = (props) => {
  const [loading, setLoading] = useState(false);
  const [fixture, setFixture] = useState({});

  let id = new URLSearchParams(props.location.search).get("id").split("/")[0];
  useEffect(() => {
    setLoading(true);
    axios.get(`${API_URL}/v4/fixtures/${id}/show`).then((res) => {
      setFixture(res?.data?.data);
      setLoading(false);
    });
  }, [id]);

  return (
    <>
      <AppLayout>
        <div className="row container_layout BLM-eventPage">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-12">
                <PageHeader
                  title={"Sports Details"}
                  history={props?.history}
                  fixture={fixture}
                />
                <ScoreBoard fixture={fixture} />
              </div>
            </div>

            {loading ? <Loader /> : <FixtureDetails fixture={fixture} />}
          </div>
          <div className="col-lg-3">
            <Betslips />
          </div>
        </div>
      </AppLayout>
      <ScrollButton />
    </>
  );
};
export default withRouter(Detail);
