import axios from "axios";
import { API_URL, cash_balance } from "./constants";
import { useDispatch } from "react-redux";
import { setBalance } from "../../store/Cashbalance/action";

axios.interceptors.request.use(function (config) {
  const token = `Bearer ${sessionStorage.getItem("token")}`;
  config.headers.Authorization = token;
  return config;
});
axios.defaults.baseURL = API_URL;

export const logoutCall = (sc, eb) => {
  +axios
    .post(`${API_URL}/v1/cashier/logout`)
    .then((response) => {
      sc();
    })
    .catch((error) => {
      eb();
    });
};

export const getRegulatoryAndParameters = (sb) => {
  return axios.get(`v2/regulatory-betting-limits`).then((res) => {
    sb(res.data);
  });
};

// -----------------------------------------------Place Bet-------------------------------------------------
export const placeBetCall = (data, sc, ec) => {
  return axios
    .post(`/client/cashier-place-bet`, data)

    .then((res) => {
      sc(res);
      // getUserInfo();
    })
    .catch((e) => {
      ec(e?.response?.data?.message ?? e?.response?.data?.error);
    });
};

export const getFixturesByLeagueIdCall = (id, matchTime = "all") => {
  return axios.get(
    `/v4/cashier/leagues/${id}/fixtures?match_time=${matchTime}&timezone_offset=${new Date().getTimezoneOffset()}&perPage=20`
  );
};
export const getFixturesBySportIdCall = (
  url,
  matchTime = "all",
  marketChange = "1",
  leagueIds = "",
  perPage = "30",
  queryChar = "?"
) => {
  return axios.get(
    `${url}${queryChar}match_time=${matchTime}&timezone_offset=${new Date().getTimezoneOffset()}&perPage=${perPage}&leagueIds=${leagueIds}`
  );
};

export const getFixtureResultCall = (sportID, matchTime = "all", day) => {
  return axios.get(
    `/v4/fixtures/result?sport_id=${sportID}&match_time=${matchTime}&day=${day}`
  );
};

export const getAllLeaguesBySport = (id, sc, ec) => {
  return axios
    .get(`/v4/sports/${id}/leagues?perPage=5000`)
    .then((res) => {
      sc(res?.data?.data ?? []);
    })
    .catch((e) => {});
};

// -----------------------------------------------SPORTS-------------------------------------------------
export const getAllSportsCall = () => {
  return axios.get(`/v4/sports`);
};

// -----------------------------------------------SEARCH FIXTURES-------------------------------------------------
export const searchFixture = (q = "") => {
  return axios.get(`/v4/search-match-id?q=${q}`);
};

export const searchUserByNumber = (q = "") => {
  return axios.get(`/v4/cashier/get-user-detail/${q}`);
};

export const verifyCode = (data) => {
  return axios.post(`/client/verify-code`, data);
};

export const getTicketBetData = (id) => {
  return axios.get(`/v4/cashier/bet-list-by-id/${id}`);
};

export const getTicketReportData = (id) => {
  return axios.get(`/v4/cashier/report?id=${id}`);
};

export const payTicketCall = (id, pin) => {
  return axios.get(`/v4/cashier/set-ticket-pin/${id}/${pin}`);
};

export const cancelTicketCall = (id) => {
  return axios.get(`/v4/cashier/cancel-ticket/${id}`);
};

export const getUserInfo = () => {
  return axios("/client/info");
  // return axios("/client/info")
  //     .then((res) => {
  //       localStorage.setItem("userInfo", JSON.stringify(res?.data));
  //     })
  //     .catch((e) => {
  //     });
};

export const withdrawalCashierCash = (id, loggedInUser, data) => {
  return axios.post(
    `/client/${id}/withdrawal-online-customer/${loggedInUser} `,
    data
  );
};

export const depositCashierCash = (id, loggedInUser, data) => {
  return axios.post(
    `/client/${id}/deposit-online-customer/${loggedInUser} `,
    data
  );
};
export const getUserBalance = () => {
  let cash_balance = JSON.parse(localStorage.getItem("userInfo"))?.data
    ?.cash_balance;
  if (cash_balance) return `${cash_balance}`;
  return `0`;
};
