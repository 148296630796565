import React from "react";
import "./App.css";
import {
  Router,
  Route,
  Switch,
  Link,
  Redirect,
  withRouter,
} from "react-router-dom";

import { SportsTickets } from "./Components/Pages/Bets/SportsTickets";
import { EnduserIndex } from "./Components/Pages/Enduser/Index";
import Sports from "./Components/Pages/Sports/Index";
import Detail from "./Components/Pages/Sports/Detail";
import { Virtuals } from "./Components/Pages/Virtuals/Virtuals";
import { NumerGames } from "./Components/Pages/NumberGames/NumerGames";
import { OnlineCustomers } from "./Components/Pages/Online Customers/OnlineCustomers";
import { Bets } from "./Components/Pages/Bets/Bets";
// import { BetSlipReciept } from "./Components/Pages/Reciept";
import Login from "./Components/Pages/Register/Login";
import { Reports } from "./Components/Pages/Reports/Reports";
import AuthenticatedRoute from "./Components/Common/AuthenticatedRoute";
import { HotKeys } from "react-hotkeys";
import { useHistory } from "react-router-dom";
import axios from "axios";

// import { Enduser } from "./Components/Pages/Enduser";
// import { NumberGameIndex } from "./Components/Pages/NumberGames"

function App(props) {
  const keyMap = {
    GOTO_SPORTS: "F1",
    GOTO_VIRTUALS: "F2",
    GOTO_NUMBER_GAMES: "F3",
    GOTO_ONLINE_CUSTOMERS: "F4",
    GOTO_BETS: "F5",
    GOTO_REPORTS: "F6",
  };
  let history = useHistory();
  const handlers = {
    GOTO_SPORTS: (event) => {
      // window.location.href = "/sports";
      history.push("/sports");
    },
    GOTO_VIRTUALS: (event) => {
      history.push("/virtual");

      // window.location.href = "/virtual";
    },
    GOTO_NUMBER_GAMES: (event) => {
      // event.keyCode = false;
      history.push("/number/games");

      // window.location.href = "/number/games";
    },
    GOTO_ONLINE_CUSTOMERS: (event) => {
      history.push("/online/customers");
      // (window.location.href = "/online/customers"),
    },
    GOTO_BETS: (event) => {
      history.push("/bets");
      // (window.location.href = "/bets")
    },
    GOTO_REPORTS: (event) => {
      history.push("/reports");
      // (window.location.href = "/reports")
    },
  };
  return (
    <>
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <Router history={history}>
          <Switch>
            <AuthenticatedRoute
              path="/sports"
              exact
              component={Sports}
              test="test"
            />

            <Route path="/fixture-details/show">
              <Detail />
            </Route>
            <AuthenticatedRoute
              path="/sports/detail"
              exact
              component={Detail}
            />

            <AuthenticatedRoute
              path="/bets/sports/tickets"
              exact
              component={SportsTickets}
            />

            <AuthenticatedRoute
              path="/endusers/index"
              exact
              component={EnduserIndex}
            />

            <AuthenticatedRoute path="/virtual" exact component={Virtuals} />

            <AuthenticatedRoute
              path="/number/games"
              exact
              component={NumerGames}
            />

            {/* <Route path="/online/customers">
            <OnlineCustomers />
          </Route> */}
            <AuthenticatedRoute
              path="/online/customers"
              exact
              component={OnlineCustomers}
            />

            {/* <Route path="/bets">
            <Bets />
          </Route> */}
            <AuthenticatedRoute path="/bets" exact component={Bets} />

            <Route path="/login">
              <Login />
            </Route>
            <AuthenticatedRoute path="/reports" exact component={Reports} />
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Router>
      </HotKeys>
    </>
  );
}

export default withRouter(App);
