import React, { useState } from "react";

export const NavVirtual = () => {
  const [virtuals, setVirtuals] = useState([
    {
      name: "Soccer",
      icon_url: "/assets/media/vsoccer.png",
    },
    {
      name: "Basketball",
      icon_url: "/assets/media/vdogs.png",
    },
    {
      name: "Baseball",
      icon_url: "/assets/media/vhorse.png",
    },
  ]);

  const _renderVirtuals = () => {
    return virtuals.map((virtual) => {
      return (
        <button
          type="button"
          className="btn btn-light btn-sm mr-1 hov-cls active-cls"
          style={{ width: "100px" }}
        >
          <p className="text-center">{virtual.name}</p>
          <img
            src={`${virtual.icon_url}`}
            alt="soccer"
            width={36}
            height={36}
          />
        </button>
      );
    });
  };

  return (
    <>
      <div className="row mb-4 " style={{ height: "110px" }}>
        <div className="p-0 mr-2 d-flex " style={{}}>
          {_renderVirtuals()}
        </div>
      </div>
    </>
  );
};
