import React, { useEffect } from "react";

function Loader() {
  return (
    <div className="CSH-loader d-flex flex-column align-items-center">
      <div className="spinner-border" role="status"></div>
      <div>Loading...</div>
    </div>
  );
}

export default Loader;
