import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScoreBoard(props) {
  const { fixture } = props;
  {
    return Object.keys(fixture).length ? (
      <div className="BLM-eventPage-scoreBoard-container">
        <div className="BLM-scoreBoard BLM-soccer">
          <div className="BLM-leagueInfo-container">
            <div className="BLM-leagueInfo">
              <i className="bl-icon-sport-soccer" />{" "}
              <span>{fixture?.tournament?.sport?.name}</span> /{" "}
              <span>{fixture?.tournament?.name}</span>
            </div>
            <div className="BLM-smsCode">Event Code: {fixture?.bb_id}</div>
          </div>
          <div className="BLM-scoreBoard-content">
            <div className="BLM-scoreInfo">
              <div className="BLM-scoreLayout-1">
                <div className="BLM-teamContainer">
                  <div className="BLM-teamName BLM-home">
                    {fixture?.competitors[0]?.name}
                  </div>
                </div>
                <div className="BLM-scoreContainer">
                  <span className="BLM-score BLM-home">-</span>
                  <span className="BLM-seperater">:</span>
                  <span className="BLM-score BLM-away">-</span>
                </div>
                <div className="BLM-teamContainer">
                  <div className="BLM-teamName BLM-away">
                    {" "}
                    {fixture?.competitors[1]?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="BLM-kickOff">
              {new Date(
                parseInt(fixture?.match_date?.$date?.$numberLong)
              ).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withRouter(ScoreBoard);
