import axios from "axios";
import React, {useEffect, useState} from "react";
import { API_URL } from "../../Components/Common/constants";
import {getUserBalance, getUserInfo, logoutCall, searchUserByNumber} from "../../Components/Common/repository";
import { useHistory } from "react-router-dom";
import { searchFixture } from "../../Components/Common/repository";
import {setBets} from "../../store/Betslip/actions";
import {useDispatch, useSelector} from "react-redux";
import toastr from "toastr";
import {setOnlineCustomer} from "../../store/OnlineCustomer/action";

export const Navbar = () => {
    let history = useHistory();
    let userData = "";
    const Balance = useSelector((state) => state?.Cashbalance?.balance ?? 0);
    const [fixtures, setFixtureValue] = useState("");
    const [search, setSearch] = useState("");
    let { fixture,listitems } = '';
    let dispatch = useDispatch();
    const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
    const currentPath = history.location.pathname;
    const _handleLogout = () => {
        logoutCall(logoutSuccessfull, logoutError);
    };
    const logoutSuccessfull = () => {
        sessionStorage.removeItem("token");
        history.push("/login");
    };
    const myFunction = () => {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }
    const logoutError = () => {
        sessionStorage.removeItem("token");
        history.push("/login");
    };
    const _renderMarketName = (f, value) => {
        let text = value;
        text = text.replaceAll("{$competitor1}", f.competitors[0].name);
        text = text.replaceAll("{$competitor2}", f.competitors[1].name);
        return text;
    };
    const setFixtureDropDown = (filter) => {
        if(filter){
            listitems = filter.map((fixture_market, index) => {
                return (
                    // <ul key={index}>
                    _renderOddsData(fixture, fixture_market,_renderMarketName(fixture, fixture_market?.market?.name))
                    // </ul>
                );
            });
            setFixtureValue(listitems);
        }
        else{
            setFixtureValue('');
        }
    };
    const checkIfOddIsSelected = (f, fm, odd) => {
        if (Bets.length && odd) {
            return (
                Bets.find((b) => b._id == f._id)
                    ?.fixture_markets?.find((fmd) => fmd._id == fm._id)
                    ?.fixture_market_odds?.find((o) => o._id == odd._id)?.selected ??
                false
            );
        }
        return false;
    };
    const _renderOddsData = (f, fixtureMarket, marketName) => {
        let myOdds = fixtureMarket.fixture_market_odds;
        let tempOddNames = myOdds?.map((o) => o?.name);
        tempOddNames = [...new Set(tempOddNames ?? [])];
        return tempOddNames?.map((ton) => {
            return (
                // <span>
                _.sortBy(myOdds, "base_line")
                    ?.filter((o) => o?.name == ton)
                    .map((odd, index) => {
                        return (
                            <li className="col-12">
                                <button
                                    key={index+marketName}
                                    id={index+marketName}
                                    className={`BLM-btnOdds BLM-btnOutComeOdds ${
                                        checkIfOddIsSelected(f, fixtureMarket, odd)
                                            ? `active filter-btn-odd`
                                            : `filter-btn-odd
                                        ${odd?.value == "OFF" ? "disabled" : ""}`
                                    }`}

                                    individualevent-odds-incdec="N"

                                    disabled={odd ? (odd.value == "OFF" ? true : false) : true}

                                    onClick={() => {
                                        if (checkIfOddIsSelected(f, fixtureMarket, odd)) {
                                            _removeOddFromBetslip(f);
                                        } else {
                                            _addToBetslip(f, fixtureMarket, odd);
                                        }
                                    }}
                                >
                                    <a className={`filter-a ${odd?.value == "OFF" ? "disabled" : ""}`} href='#' onClick={e => e.preventDefault()}>
                                <span className="BLM-outcome">
                                {marketName} {_renderOddName(f, odd)}

                                </span>
                                        <span className="BLM-odds">
                                            {odd?.value == "OFF" ? "-" : parseFloat(odd?.value)?.toFixed(2)}
                                </span>
                                    </a>
                                </button>
                            </li>

                        );
                    })
                // </span>
            );
        });
    };
    const _renderOddName = (f, odd) => {
        if (odd.player) {
            return odd.player.name;
        }
        let text = odd.market_spec.name;
        text = text.replaceAll("{$competitor1}", f.competitors[0].name);
        text = text.replaceAll("{$competitor2}", f.competitors[1].name);
        text = text.replaceAll("{total}", odd.special_bet_value);
        return text;
    };
    const _addToBetslip = (fixture, fixtureMarket, odd) => {
        let copyFixture = _.cloneDeep(fixture);
        let oddsData = copyFixture.fixture_markets
            .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
            .fixture_market_odds.map((odd) => {
                odd.selected = false;
                return odd;
            });

        copyFixture.fixture_markets.find(
            (fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id
        ).odds = oddsData;

        copyFixture.fixture_markets
            .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
            .odds.find((oddData) => oddData._id == odd._id).selected = true;

        let foundFixture = Bets.find((fix) => fix._id == fixture._id);
        if (foundFixture) {
            let d = _.cloneDeep(Bets);
            let index = d.findIndex((i) => i._id == fixture._id);
            if (index > -1) {
                d[index] = copyFixture;
            }
            dispatch(setBets(d));
        } else {
            dispatch(setBets([...Bets, copyFixture]));
        }
    };
    const getQuickBetOnMatchID = (d) => {
        searchFixture(d)
            .then((response) => {
                fixture=response?.data?.data;
            })
            .catch((err) => {
            })
            .finally(() => {
                setFixtureDropDown(fixture?.fixture_markets);
            });
    };

    const searchByNumber = () => {
        if(search){
            searchUserByNumber(search)
                .then((response) => {
                    if(response?.data?.error){
                        dispatch(setOnlineCustomer([]))
                        toastr.error(response?.data?.error);
                    }
                    else{
                        dispatch(setOnlineCustomer(response?.data?.data))
                    }
                });
        }else{
            dispatch(setOnlineCustomer([]))
            toastr.error("Please enter mobile number");
        }

    };
    $("#mobile-number-search").on("input", function() {
        if (/^0/.test(this.value)) {
            this.value = this.value.replace(/^0/, "")
        }
        if (/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(this.value)) {
            this.value = this.value.replace(/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        }
    })

    const _removeOddFromBetslip = (fixture) => {
        dispatch(setBets(_.cloneDeep(Bets).filter((fd) => fd._id != fixture._id)));
    };

    return (
        <div>
            {
                (currentPath!='/online/customers') ?
                    <div className="row mb-3 mt-3">
                        <div className="col-lg-3">
                        <input
                            type="text"
                            className="form-control"
                            id="match-id-search"
                            placeholder="Match ID Search"
                            aria-label="Match Search"
                            style={{ borderColor: "#f20d4e", borderStyle: "solid" }}
                            onChange={(e) => {
                                getQuickBetOnMatchID(e.target.value);
                            }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <div className="dropdown dropdown-division">
                                <a className="dropdown-toggle form-control btn-dropdown" href="#" role="button" id="dropdownMenuLink"
                                   data-bs-toggle="dropdown" aria-expanded="false" >
                                    Quick Bet
                                </a>

                                <ul id="myUL" className={ fixtures ? 'dropdown-menu dropdown-ul selectpicker' : 'dropdown-ul-no-result dropdown-menu dropdown-ul selectpicker'} aria-labelledby="dropdownMenuLink">
                                    <div className="bs-searchbox">
                                        <input type="text" placeholder="Search Bet..." className="form-control" autoComplete="off" id="myInput" onKeyUp={myFunction} role="textbox" aria-label="Search" spellCheck="false" data-ms-editor="true"/>
                                    </div>
                                    { fixtures ? fixtures : <li style={{'paddingLeft': '9px'}}>No Result Found</li>}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-light" style={{ borderColor: "#f20d4e", borderStyle: "solid" }}>
                                Search
                            </button>
                        </div>
                        <div className="col-lg-3 text-end">
                            <span style={{marginRight:"20px",color:"red"}}>Payable Cash: {Balance}</span>
                            <button
                                onClick={() => {
                                    _handleLogout();
                                }}
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "none",
                                    borderRadius: "5px",
                                    backgroundColor: "#f20d4e",
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-power"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M7.5 1v7h1V1h-1z" />
                                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                                </svg>
                            </button>
                            {/* Balance: 3 000 <button type="button" className="btn btn-outline-dark"> <MdLogout /></button> */}

                            {/* <input type="text" className="form-control" placeholder="City" aria-label="City" /> */}
                        </div>
                    </div> :
                    <div className="row mb-3 mt-3">
                        <div className="col-lg-3">
                            <input
                                type="tel"
                                className="form-control"
                                id="mobile-number-search"
                                placeholder="Mobile Number Search"
                                aria-label="Mobile Search"
                                style={{ borderColor: "#f20d4e", borderStyle: "solid" }}
                                maxLength={9}
                                onChange={(e => {
                                    setSearch(e.target.value)
                                })}
                            />
                        </div>
                        <div className="col-lg-6">
                            <button type="button" className="btn btn-light" style={{ borderColor: "#f20d4e", borderStyle: "solid" }} onClick={(e) => {
                                searchByNumber()
                            }}>
                                Search
                            </button>
                        </div>
                        <div className="col-lg-3 text-end">
                            <span style={{marginRight:"20px",color:"red"}}>Payable Cash: {Balance}</span>
                            <button
                                onClick={() => {
                                    _handleLogout();
                                }}
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "none",
                                    borderRadius: "5px",
                                    backgroundColor: "#f20d4e",
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-power"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M7.5 1v7h1V1h-1z" />
                                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                                </svg>
                            </button>
                            {/* Balance: 3 000 <button type="button" className="btn btn-outline-dark"> <MdLogout /></button> */}

                            {/* <input type="text" className="form-control" placeholder="City" aria-label="City" /> */}
                        </div>
                    </div>
            }

        </div>
    );
};
